import { Popover, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  BellIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'flowbite-react';
import { Fragment, MouseEventHandler, useEffect, useState } from 'react';
import {
  FaCog,
  FaRegMoon,
  FaRegSun,
  FaSignOutAlt,
  FaTerminal,
  FaUser,
  FaUserCircle,
  FaUserNinja,
} from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import stringToColor from 'string-to-color';
import { ITeam } from '../../api/services/team.service';
import { globalThemeStyles } from '../../config/globalStyles';
import { RoutesEnum } from '../../enums';
import { IAuthenticatedUser, useAuth } from '../../hooks/useAuth';
import { useGlobalState } from '../../state';
import { Theme } from '../../types';
import { HoverDropdown } from '../HoverDropdown';
import { KTRLogo } from '../shared/ktr';
import { ProfileDialog } from './ProfileDialog';
import SearchBar from './SearchBar';
import { NavbarPlayer } from './navbar/NavbarPlayer';
import { ProfilePicDropdown } from './navbar/ProfilePicDropdown';
import { TenantSelector } from './navbar/TenantSelector';
import { AdminDialog } from './AdminDialog';
import { FEATURES } from '../../config/features';
import StatusBadge from '../shared/StatusBadge';

interface HeaderItem {
  label: string;
  items?: (Omit<HeaderItem, 'to'> & { to: string })[];
  to?: string;
  isAllowedToSee?: (
    activeUser: IAuthenticatedUser | null,
    activeTeam: ITeam | null
  ) => boolean | null;
  disabled?: boolean;
}

const LINKS: HeaderItem[] = [
  { label: 'Dashboard', to: RoutesEnum.Dashboard },
  {
    label: 'Library',
    isAllowedToSee: (
      activeUser: IAuthenticatedUser | null,
      activeTeam: ITeam | null
    ) =>
      activeTeam &&
      (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
        activeTeam.teamMembershipDTO?.role === 'MEMBER'),
    items: [
      {
        label: 'Submissions',
        to: RoutesEnum.Submissions,
        isAllowedToSee: (
          activeUser: IAuthenticatedUser | null,
          activeTeam: ITeam | null
        ) =>
          activeTeam &&
          (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
            activeTeam.teamMembershipDTO?.role === 'MEMBER'),
      },
      {
        label: 'Albums',
        to: RoutesEnum.Albums,
        isAllowedToSee: (
          activeUser: IAuthenticatedUser | null,
          activeTeam: ITeam | null
        ) =>
          activeTeam &&
          (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
            activeTeam.teamMembershipDTO?.role === 'MEMBER'),
      },
      {
        label: 'Tracks',
        to: RoutesEnum.Tracks,
        isAllowedToSee: (
          activeUser: IAuthenticatedUser | null,
          activeTeam: ITeam | null
        ) =>
          activeTeam &&
          (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
            activeTeam.teamMembershipDTO?.role === 'MEMBER'),
      },
      {
        label: 'Artists',
        to: RoutesEnum.Artists,
        isAllowedToSee: (
          activeUser: IAuthenticatedUser | null,
          activeTeam: ITeam | null
        ) =>
          activeTeam &&
          (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
            activeTeam.teamMembershipDTO?.role === 'MEMBER'),
      },
      {
        label: 'Composers',
        to: RoutesEnum.Composers,
        isAllowedToSee: (
          activeUser: IAuthenticatedUser | null,
          activeTeam: ITeam | null
        ) =>
          activeTeam &&
          (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
            activeTeam.teamMembershipDTO?.role === 'MEMBER'),
      },
    ],
  },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface CustomNavLinkProps {
  name: string;
  url: string;
  theme: Theme;
  testid?: string;
  // icon: React.ReactNode;
}

export const getNavbarItemClasses = (isActive: boolean, theme: Theme) => {
  if (isActive && theme === 'light') return 'border-indigo-500 text-gray-900';
  if (isActive && theme === 'dark') return 'border-indigo-500 text-white';
  if (!isActive && theme === 'light')
    return 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700';
  return 'border-transparent text-white hover:border-gray-300 hover:text-gray-200';
};

export function CustomNavLink({
  name,
  url,
  theme,
  testid,
}: CustomNavLinkProps) {
  return (
    <NavLink
      data-testid={testid || ''}
      to={url}
      className={({ isActive }) =>
        `inline-flex items-center border-b-4 px-1 pt-1 font-medium ${getNavbarItemClasses(
          isActive,
          theme
        )}`
      }
    >
      {name}
    </NavLink>
  );
}

// interface StudioNavLinkProps {
//   name: string;
//   url: string;
//   activeProject: IProject;
//   // icon: React.ReactNode;
// }

// export function StudioNavLink({
//   name,
//   url,
//   activeProject,
// }: StudioNavLinkProps) {
//   return (
//     <NavLink
//       to={url}
//       className={({ isActive }) =>
//         `inline-flex items-center px-1 pt-1 border-b-4 font-medium ${
//           isActive
//             ? "border-indigo-500 text-gray-900"
//             : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
//         }`
//       }
//     >
//       <span className="mr-2">{name}</span>
//       {/* <FaChevronRight />
//       <span className="ml-2 text-indigo-500 text-xs">{activeProject.name}</span> */}
//     </NavLink>
//   );
// }

interface CustomNavLinkCollapsedProps {
  name: string;
  url: string;
  close: () => void;
  testid?: string;
}

export function CustomNavLinkCollapsed({
  name,
  url,
  close,
  testid,
}: CustomNavLinkCollapsedProps) {
  return (
    <NavLink
      data-testid={testid || ''}
      to={url}
      onClick={() => close()}
      className={({ isActive }) =>
        `block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800 ${
          isActive ? '' : ''
        }`
      }
    >
      {name}
    </NavLink>
  );
}

export default function Navbar() {
  const { theme, setTheme } = useGlobalState();
  let navigate = useNavigate();
  const { logout, user, teams, activeTeam, isFeatureActive } = useAuth();

  const [settingsOpen, setSettingsOpen] = useState(false);
  // const [subMgmtOpen, setSubMgmtOpen] = useState(false);
  const [isProfileModalOpened, setIsProfileModalOpened] = useState(false);
  const [isAdminModalOpened, setIsAdminModalOpened] = useState(false);
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const [appEnvironment, setAppEnvironment] = useState('');

  async function triggerLogout() {
    logout();
    navigate('/login');
    toast.info('Logged out successfully');
  }

  const onClickViewProfile = () => {
    setIsProfileModalOpened(true);
  };

  const onClickViewAdmin = () => {
    setIsAdminModalOpened(true);
  };

  const handleTheme = () => {
    setTheme && setTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    const env = mapAppEnvironment(window.location.host);
    console.log(env);
    setAppEnvironment(env);
  }, []);

  function extractJiraTicketKey(hostname: string) {
    const regex = /MVP-\d+/g;
    const matches = hostname.match(regex);

    return matches ? matches[0] : null;
  }

  function mapAppEnvironment(host: string) {
    console.log(host);
    host = host.toUpperCase();
    if (host.includes('LOCALHOST') || host.includes('127.0.0.1')) {
      return 'local';
    } else if (host.includes('DEV')) {
      return 'dev';
    }
    const jiraTicketKey = extractJiraTicketKey(host);
    if (jiraTicketKey) {
      return jiraTicketKey.toUpperCase();
    }
    return 'production';
  }

  function getEnvBadgeColor(env: string) {
    switch (env) {
      case 'local':
        return 'yellow';
      case 'dev':
        return 'blue';
      default:
        return 'teal';
    }
  }

  return (
    <>
      <header className={classNames(globalThemeStyles.headerNav)}>
        <div className='mx-auto max-w-1920 px-2 sm:px-4 lg:px-8'>
          <Popover className='flex h-16 justify-between'>
            <div className='flex items-center px-2 lg:px-0'>
              {teams && teams.length > 1 && <TenantSelector />}

              <div
                className='flex flex-shrink-0 cursor-pointer items-center'
                onClick={() => navigate('/')}
              >
                {/* <div > */}
                <div className='h-10 w-10'>
                  <KTRLogo />
                </div>
                <span className='ml-2 text-2xl font-bold'>Studio</span>
                {/* </div> */}
              </div>
              <nav
                aria-label='Global'
                className='hidden lg:ml-6 lg:flex lg:space-x-8'
              >
                {LINKS.filter(
                  (nav) =>
                    nav.isAllowedToSee === undefined ||
                    nav.isAllowedToSee(user, activeTeam)
                ).map((item, i) => {
                  if (!item.items && item.to) {
                    return (
                      <CustomNavLink
                        name={item.label}
                        url={item.to}
                        key={item.label}
                        theme={theme}
                      />
                    );
                  }

                  if (item.disabled) {
                    return (
                      <DisabledItem
                        label={item.label}
                        theme={theme}
                        key={item.label}
                      />
                    );
                  }

                  if (!!item.items?.length) {
                    const visibleItems = item.items?.filter(
                      (link) =>
                        link.isAllowedToSee === undefined ||
                        link.isAllowedToSee(user, activeTeam)
                    );
                    return (
                      <HoverDropdown
                        testid={`HoverDropdown-${item.label}`}
                        key={item.label}
                        trigger={
                          <div
                            className={`inline-flex items-center border-b-4 px-1 pt-1 font-medium ${getNavbarItemClasses(
                              false,
                              theme
                            )}`}
                            key={item.label}
                          >
                            {item.label}
                          </div>
                        }
                      >
                        <div className='px-2'>
                          {visibleItems.map((link) => {
                            if (link.disabled) {
                              return (
                                <DisabledItem
                                  label={link.label}
                                  theme={theme}
                                  key={link.label}
                                />
                              );
                            }

                            return (
                              <div key={link.label}>
                                <CustomNavLink
                                  testid={`CustomNavLink-${link.label}`}
                                  name={link.label}
                                  url={link.to}
                                  theme={theme}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </HoverDropdown>
                    );
                  }

                  return null;
                })}
              </nav>
            </div>
            <div className='flex items-center lg:hidden'>
              {/* <button
              onClick={() => setSearchBarOpen(!searchBarOpen)}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            >
              <span className="sr-only">Search</span>
              <MagnifyingGlassIcon className="h-6 w-6" aria-hidden="true" />
            </button> */}
              {/* Mobile menu button */}
              <Popover.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
                <span className='sr-only'>Open main menu</span>
                <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
              </Popover.Button>
            </div>
            <Transition.Root as={Fragment}>
              <div className='lg:hidden'>
                <Transition.Child
                  as={Fragment}
                  enter='duration-150 ease-out'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='duration-150 ease-in'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <Popover.Overlay
                    className='fixed inset-0 z-20 bg-black bg-opacity-25'
                    aria-hidden='true'
                  />
                </Transition.Child>

                <Transition.Child
                  as={Fragment}
                  enter='duration-150 ease-out'
                  enterFrom='opacity-0 scale-95'
                  enterTo='opacity-100 scale-100'
                  leave='duration-150 ease-in'
                  leaveFrom='opacity-100 scale-100'
                  leaveTo='opacity-0 scale-95'
                >
                  <Popover.Panel
                    focus
                    className='absolute top-0 right-0 z-30 w-full max-w-none origin-top transform p-2 transition'
                  >
                    {({ close }) => (
                      <div className='divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5'>
                        <div className='pt-3 pb-2'>
                          <div className='flex items-center justify-between px-4'>
                            <div
                              className='flex cursor-pointer items-center'
                              onClick={() => navigate('/')}
                            >
                              <div className='h-10 w-10'>
                                <KTRLogo />
                              </div>
                              <span className='ml-2 text-2xl font-bold'>
                                Studio
                              </span>
                            </div>
                            <div className='-mr-2'>
                              <Popover.Button className='inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500'>
                                <span className='sr-only'>Close menu</span>
                                <XMarkIcon
                                  className='h-6 w-6'
                                  aria-hidden='true'
                                />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className='mt-3 space-y-1 px-5'>
                            {/* {navigation
                              .filter(
                                (nav) =>
                                  nav.isAllowedToSee === undefined ||
                                  nav.isAllowedToSee(user, activeTeam)
                              )
                              .map((item) => (
                                <CustomNavLinkCollapsed
                                  name={item.name}
                                  url={item.href}
                                  close={close}
                                  key={item.name}
                                />
                              ))} */}
                            {LINKS.filter(
                              (nav) =>
                                nav.isAllowedToSee === undefined ||
                                nav.isAllowedToSee(user, activeTeam)
                            ).map((item) => {
                              if (!item.items && item.to) {
                                return (
                                  <CustomNavLinkCollapsed
                                    name={item.label}
                                    url={item.to}
                                    key={item.label}
                                    close={close}
                                  />
                                );
                              }

                              if (item.disabled) {
                                return (
                                  <DisabledItem
                                    label={item.label}
                                    theme={theme}
                                    key={item.label}
                                  />
                                );
                              }

                              if (!!item.items?.length) {
                                return (
                                  <div key={item.label}>
                                    <HoverDropdown
                                      key={item.label}
                                      trigger={
                                        <div
                                          className={`inline-flex items-center border-b-4 px-1 pt-1 font-medium ${getNavbarItemClasses(
                                            false,
                                            theme
                                          )}`}
                                        >
                                          {item.label}
                                        </div>
                                      }
                                    >
                                      <div className='px-2'>
                                        {item.items?.map((link) => {
                                          if (link.disabled) {
                                            return (
                                              <DisabledItem
                                                label={link.label}
                                                theme={theme}
                                                key={link.label}
                                              />
                                            );
                                          }

                                          return (
                                            <div key={link.label}>
                                              <CustomNavLinkCollapsed
                                                name={link.label}
                                                url={link.to}
                                                close={close}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </HoverDropdown>
                                  </div>
                                );
                              }

                              return null;
                            })}
                            {/* <div
                            onClick={() => setSubMgmtOpen(true)}
                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                          >
                            Manage Subscriptions
                          </div> */}
                          </div>
                        </div>
                        <div className='pt-4 pb-2'>
                          <div className='flex items-center px-5'>
                            <div className='flex-shrink-0'>
                              <FaUserCircle
                                className='h-8 w-8 rounded-full bg-white'
                                color={stringToColor(user?.id)}
                              />
                            </div>
                            <div className='ml-3'>
                              <div className='text-base font-medium text-gray-800'>
                                {user?.firstname}
                              </div>
                              <div className='text-sm font-medium text-gray-500'>
                                {user?.email}
                              </div>
                            </div>
                          </div>
                          <div className='mt-3 space-y-1 px-2'>
                            <div
                              className='block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                              onClick={() => {
                                handleTheme();
                                close();
                              }}
                            >
                              {theme === 'light' ? 'Dark mode' : 'Light mode'}
                            </div>

                            <div
                              className='block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                              onClick={() => {
                                onClickViewProfile();
                                close();
                              }}
                            >
                              User Profile
                            </div>

                            {activeTeam &&
                              (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
                                activeTeam.teamMembershipDTO?.role ===
                                  'MEMBER') && (
                                <div
                                  className='block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                                  onClick={() => {
                                    navigate('/team');
                                    close();
                                  }}
                                >
                                  Label Settings
                                </div>
                              )}

                            {user?.role === 'ADMIN' && (
                              <>
                                <div
                                  className='block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                                  onClick={() => {
                                    onClickViewAdmin();
                                    close();
                                  }}
                                >
                                  Admin info
                                </div>
                                {isFeatureActive(
                                  FEATURES.KTR_IMPERSONATION
                                ) && (
                                  <div
                                    className='block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                                    onClick={() => {
                                      navigate('/impersonate');
                                      close();
                                    }}
                                  >
                                    Impersonate User
                                  </div>
                                )}
                              </>
                            )}

                            <div
                              className='block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800'
                              onClick={() => {
                                triggerLogout();
                                close();
                              }}
                            >
                              Log out
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </Transition.Root>
            <div className='hidden w-1/4 grow justify-center p-2 xl:flex'>
              <NavbarPlayer />
            </div>
            <div className='hidden lg:ml-4 lg:flex lg:items-center'>
              {/* <button
              className="bg-indigo-600 text-white rounded px-3 py-1 hover:bg-indigo-800 font-semibold w-full mr-4"
              onClick={() => setSubMgmtOpen(true)}
            >
              Manage Subscriptions
            </button> */}
              {user && isFeatureActive(FEATURES.KTR_GLOBAL_SEARCH) && (
                <button
                  onClick={() => setSearchBarOpen(!searchBarOpen)}
                  type='button'
                  className='mr-2 flex-shrink-0 rounded-full p-1 text-slate-700 hover:text-gray-500 focus:ring-blue-500 dark:bg-slate-800 dark:text-white hover:dark:bg-slate-600'
                >
                  <span className='sr-only'>Search</span>
                  <MagnifyingGlassIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              )}
              {appEnvironment !== 'production' && (
                <div onClick={onClickViewAdmin}>
                  <StatusBadge
                    status={appEnvironment}
                    className={`cursor-pointer hover:bg-${getEnvBadgeColor(
                      appEnvironment
                    )}-200 bg-${getEnvBadgeColor(
                      appEnvironment
                    )}-200 text-${getEnvBadgeColor(appEnvironment)}-800`}
                    color={getEnvBadgeColor(appEnvironment)}
                  />
                </div>
              )}
              {/* Profile dropdown */}
              <ProfilePicDropdown
                onClickViewProfile={onClickViewProfile}
                onClickViewAdmin={onClickViewAdmin}
              />
            </div>
          </Popover>
        </div>
        {/* <GlobalSettingsDialog isOpen={settingsOpen} setIsOpen={setSettingsOpen} /> */}
        {/* <SubscriptionDialog isOpen={subMgmtOpen} setIsOpen={setSubMgmtOpen} /> */}
        <SearchBar isOpen={searchBarOpen} setIsOpen={setSearchBarOpen} />
      </header>
      <ProfileDialog
        isOpen={isProfileModalOpened}
        setIsOpen={setIsProfileModalOpened}
      />
      <AdminDialog
        isOpen={isAdminModalOpened}
        setIsOpen={setIsAdminModalOpened}
      />
    </>
  );
}

const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
  e.stopPropagation();
};

const DisabledItem = ({ label, theme }: { label: string; theme: Theme }) => (
  <div onClick={onClick}>
    <Tooltip content='Coming Soon'>
      <div
        className={`inline-flex cursor-pointer items-center border-b-4 px-1 pt-1 font-medium opacity-50 ${getNavbarItemClasses(
          false,
          theme
        )}`}
      >
        {label}
      </div>
    </Tooltip>
  </div>
);
