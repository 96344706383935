import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { FaExpand } from 'react-icons/fa';
import { Tooltip } from 'flowbite-react';

export default function ExportOptionMenu({
  items,
  btnLabel,
}: {
  items: {
    label: string;
    onClick: () => void;
    icon?: JSX.Element;
    disabled?: boolean;
    disabledTooltipMessage?: string;
  }[];
  btnLabel?: JSX.Element;
}) {
  return (
    <Menu
      as='div'
      className='inline-block text-left'
      onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
        event.stopPropagation()
      }
    >
      <Menu.Button className='flex items-center justify-center'>
        <div className='flex justify-end space-x-4'>
          <div className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'>
            {btnLabel || (
              <>
                <div>Export</div>
                <FaExpand />
              </>
            )}
          </div>
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-24 z-10 mt-2 w-56 origin-top-right divide-y divide-slate-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          {items.map((item) => (
            <div className='px-1 py-1' key={item.label}>
              <Menu.Item>
                {({ active }) => {
                  const btn = (
                    <button
                      className={`${
                        active ? 'bg-indigo-500 text-white' : 'text-slate-700'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:cursor-not-allowed disabled:opacity-50`}
                      onClick={item.onClick}
                      disabled={item.disabled}
                      data-testid={`${item.label}-export-option`}
                    >
                      {item.icon || (
                        <FaExpand className='mr-2 h-5 w-5' aria-hidden='true' />
                      )}
                      {item.label}
                    </button>
                  );
                  if (item.disabled && item.disabledTooltipMessage) {
                    return (
                      <Tooltip content={item.disabledTooltipMessage}>
                        {btn}
                      </Tooltip>
                    );
                  }
                  return btn;
                }}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
