import SubmissionSection from '../components/dashboard/SubmissionSection';
import { useAuth } from '../hooks/useAuth';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../api';
import { IProject } from '../api/services/project.service';
import NoTeamSection from '../components/dashboard/NoTeamSection';
import MidnightLogoViolet from '../components/global/midnight_violet.png';
import MidnightLogoYellow from '../components/global/midnight_yellow.png';
import { useGlobalState } from '../state';

export default function Submissions() {
  const { user, activeTeam } = useAuth();
  const { theme } = useGlobalState();

  const [projects, setProjects] = useState<IProject[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activeTeam !== null) {
      fetchProjects();
    }
  }, [activeTeam]);

  async function fetchProjects() {
    try {
      setLoading(true);
      const { data } = await api.project.getProjects();
      if (data.result) {
        setProjects(data.result);
        setLoading(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center'>
          {/* <TourOnboarding /> */}

          {/* <h2 className="ext-center text-2xl font-bold">
            Welcome back, {user ? user.firstname : ""}!
          </h2> */}

          {/* Projects */}
          {activeTeam && (
            <>
              <div className=''>
                {theme === 'dark' && (
                  <img
                    className='mt-4 h-16'
                    src={MidnightLogoYellow}
                    alt='MidnightLogoYellow'
                  />
                )}
                {theme !== 'dark' && (
                  <img
                    className='mt-4 h-16'
                    src={MidnightLogoViolet}
                    alt='MidnightLogoViolet'
                  />
                )}
              </div>
              <h2 className='ext-center text-2xl font-bold'>
                {activeTeam.name}
              </h2>
              <div className='mt-20 flex w-full px-5 md:mt-8 md:px-20 lg:px-36'>
                <SubmissionSection
                  className='h-128'
                  projects={projects}
                  loading={loading}
                />
              </div>
            </>
          )}
          {!activeTeam && (
            <div className='mt-20 flex w-full px-5 md:mt-8 md:px-20 lg:px-36'>
              <NoTeamSection />
            </div>
          )}
        </div>
      )}
    </>
  );
}
