import { Combobox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { classNames } from '../../utils';

interface Props<T> {
  options: T[];
  onChange: (v: T) => void;
  filterFn: (query: string) => (v: T) => boolean;
  getValue: (v: T) => string;
  displayValue: (v: T) => string;
  displayOption: (v: T) => string | JSX.Element;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  error?: boolean;
  value: any;
}

export default function DropdownAutocomplete<T>({
  options,
  filterFn,
  displayValue,
  displayOption,
  disabled,
  placeholder = '',
  label,
  error,
  getValue,
  value,
  onChange,
}: Props<T>) {
  const [query, setQuery] = useState('');

  const filteredOptions =
    query === '' ? options : options.filter(filterFn(query));

  return (
    <>
      <div
        className={`mb-1 block text-sm font-medium ${
          error ? 'text-red-500' : ''
        }`}
      >
        {label}
      </div>
      <Combobox value={value} onChange={onChange} nullable disabled={disabled}>
        <div className='relative'>
          <div className='relative w-full cursor-default overflow-hidden rounded-lg border bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 dark:border-gray-600 dark:bg-slate-800 sm:text-sm'>
            <Combobox.Input
              className='w-full border-none bg-gray-50 py-2 pl-3 pr-10 text-sm leading-5 focus:ring-0 dark:bg-slate-800 dark:text-gray-200'
              placeholder={placeholder}
              displayValue={displayValue}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
              <FaChevronDown
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-slate-900 sm:text-sm'>
              {filteredOptions.map((option, i) => (
                <Combobox.Option
                  key={i}
                  className={({ active, selected }) =>
                    `relative cursor-pointer select-none py-2 pl-4 pr-4 ${classNames(
                      active
                        ? 'bg-indigo-600 text-white'
                        : 'text-gray-900 dark:text-gray-100',
                      selected ? 'bg-indigo-500 text-white' : ''
                    )}`
                  }
                  value={getValue(option)}
                >
                  <>{displayOption(option)}</>
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </>
  );
}
