import { useState } from 'react';
import { ITag } from '../../../../api/services/tag.service';
import { TagCategory } from '../../../../types';
import { useAuth } from '../../../../hooks/useAuth';
import { FEATURES } from '../../../../config/features';

export function TagPicker({
  tagCategory,
  activeTags,
  allTags,
  handleTagSelect,
}: {
  tagCategory: TagCategory;
  activeTags: ITag[];
  allTags: ITag[];
  handleTagSelect(tag: ITag): void;
}) {
  const { isFeatureActive } = useAuth();
  const [tagsQuery, setTagsQuery] = useState('');
  const filteredTags =
    tagsQuery === ''
      ? allTags
      : allTags.filter((tags) => {
          return tags.name.toLowerCase().includes(tagsQuery.toLowerCase());
        });

  function determineTagColor(tag: ITag) {
    const matchingTag = activeTags.find((activeTag) => activeTag.id === tag.id);
    if (matchingTag) {
      if (isFeatureActive(FEATURES.KTR_AI_TAGGING)) {
        if (
          matchingTag.createdBy &&
          matchingTag.createdBy.indexOf('API') !== -1
        ) {
          return 'bg-teal-500 dark:bg-teal-600 dark:text-white';
        }
      }
      return 'bg-blue-700 dark:bg-blue-600 dark:text-white';
    }
    return 'bg-blue-300 dark:bg-blue-900 dark:text-gray-300';
  }

  return (
    <div
      className={`my-1 flex flex-grow cursor-pointer flex-col rounded-md border-2 border-gray-200 dark:border-gray-600`}
    >
      <div className='MiniScrollbar relative flex-grow overflow-auto'>
        <div className='absolute top-0 left-0 w-full px-2'>
          <div className='animate my-3 flex flex-wrap  justify-start duration-1000'>
            {filteredTags.map((tag, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleTagSelect(tag)}
                  className={`my-1 mx-1 flex h-6 cursor-pointer select-none items-center rounded-md px-2 text-center text-sm font-medium text-white ${determineTagColor(
                    tag
                  )}`}
                >
                  {tag.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <hr className='h-px border-0 bg-gray-200 dark:bg-gray-700' />
      <div className='flex w-full items-center justify-around px-1'>
        <div className='text-md mb-2 mt-2 w-32 font-semibold text-gray-600 dark:text-gray-300'>
          {tagCategory.name}
        </div>
        <input
          onChange={(event) => setTagsQuery(event.target.value)}
          placeholder='Search Tags'
          value={tagsQuery}
          onClick={(e) => e.stopPropagation()}
          className='h-8 rounded-md border-2 border-gray-100 pl-3 text-sm leading-5 focus:ring-0 dark:border-gray-800 dark:bg-slate-800'
        />
      </div>
    </div>
  );
}
