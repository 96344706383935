import {
  BiBook,
  BiCube,
  BiEdit,
  BiListPlus,
  BiMusic,
  BiPalette,
  BiPurchaseTag,
  BiSearchAlt,
  BiSun,
  BiTag,
  BiTrash,
} from 'react-icons/bi';
import { ITeam } from '../../api/services/team.service';
import StatusBadge from '../shared/StatusBadge';
import { useEffect, useState } from 'react';
import { ITag } from '../../api/services/tag.service';
import api from '../../api';
import GenericDialog from '../shared/GenericDialog';
import { FaTag, FaTimes } from 'react-icons/fa';
import { globalThemeStyles } from '../../config/globalStyles';
import { toast } from 'react-toastify';
import Input from '../shared/Input';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { MdPiano } from 'react-icons/md';

export function TagSection({ team }: { team: ITeam }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<ITag[]>([]);
  const [tagTypes, setTagTypes] = useState<ITag[]>([]);

  const [isTagAddDialogOpen, setIsTagAddDialogOpen] = useState(false);
  const [isTagListDialogOpen, setIsTagListDialogOpen] = useState(false);
  const [selectedType, setSelectedType] = useState<string>('');
  const [selectedTag, setSelectedTag] = useState<ITag | undefined>();

  useEffect(() => {
    fetchTags();
  }, [team]);

  const getUniqueItems = (arr: any[], key: string) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  async function fetchTags() {
    setLoading(true);
    const response = await api.tag.getTags();
    setLoading(false);
    const responseTags = response.data.result;
    setTags(responseTags);
    setTagTypes(getUniqueItems(responseTags, 'type'));
  }

  async function addTag(name: string, type: string) {
    try {
      const response = await api.tag.addTag({
        name,
        teamId: team.id,
        type: type.toUpperCase(),
      });
      toast.success(`Tag ${name} added successfully!`);
      setIsTagAddDialogOpen(false);
      fetchTags();
    } catch (e) {
      console.error(e);
    }
  }

  async function updateTag(id: string, tag: Partial<ITag>) {
    try {
      const response = await api.tag.updateTagPartially(id, tag);
      toast.success(`Tag ${tag.name} updated successfully!`);
      setIsTagAddDialogOpen(false);
      fetchTags();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div>
      <NewTagForm
        selectedTag={selectedTag}
        isOpen={isTagAddDialogOpen}
        setIsOpen={setIsTagAddDialogOpen}
        types={tagTypes.map((tagType) => tagType.type)}
        addTag={addTag}
        updateTag={updateTag}
      />
      <ListTagsForm
        isOpen={isTagListDialogOpen}
        setIsOpen={setIsTagListDialogOpen}
        type={selectedType}
        tags={tags.filter((tag) => tag.type === selectedType)}
        reloadTags={fetchTags}
        setIsEditOpen={(tagId) => {
          const tag = tags.find((tag) => tag.id === tagId);
          if (tag) {
            // setIsTagListDialogOpen(false);
            setIsTagAddDialogOpen(true);
            setSelectedTag(tag);
          }
        }}
      />

      <div className='flex flex-col space-y-5'>
        <div className='flex items-center justify-between'>
          <div className='flex items-center space-x-2'>
            <BiPurchaseTag className='text-2xl text-indigo-500' />
            <div className='text-lg font-semibold'>Tag Management</div>
          </div>
          <div
            className='cursor-pointer rounded-lg bg-indigo-500 p-1 px-2'
            onClick={() => setIsTagAddDialogOpen(true)}
          >
            <BiListPlus className='text-xl text-white' />
          </div>
        </div>

        <div className='flex w-full flex-wrap items-end justify-start space-x-2 space-y-2 overflow-scroll'>
          {tagTypes.map((tagType) => (
            <div
              key={tagType.type}
              className='flex cursor-pointer'
              onClick={() => {
                setSelectedType(tagType.type);
                setIsTagListDialogOpen(true);
              }}
            >
              <StatusBadge
                status={tagType.type}
                className={mapTagTypeToColorClass(tagType.type)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function NewTagForm({
  isOpen,
  setIsOpen,
  types,
  addTag,
  updateTag,
  selectedTag,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  types: string[];
  addTag: (name: string, type: string) => void;
  updateTag: (id: string, tag: Partial<ITag>) => void;
  selectedTag: ITag | undefined;
}) {
  const [name, setName] = useState(selectedTag ? selectedTag.name : '');
  const [type, setType] = useState(selectedTag ? selectedTag.type : '');

  useEffect(() => {
    setName(selectedTag ? selectedTag.name : '');
    setType(selectedTag ? selectedTag.type : '');
  }, [selectedTag]);

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={
        <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
      }
      header={selectedTag ? `Edit tag ${selectedTag.name}` : 'Add a new tag'}
      headerIcon={<FaTag size={20} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={
        <div className='mt-2 flex items-center justify-end gap-x-3'>
          {selectedTag && (
            <button
              className='rounded bg-emerald-600 p-2 px-4 font-semibold text-white hover:bg-emerald-800 disabled:cursor-not-allowed disabled:bg-emerald-300'
              type='submit'
              onClick={() => updateTag(selectedTag.id, { name, type })}
              data-testid='reject-submission-submit-btn'
              disabled={name.length === 0 || type.length === 0}
            >
              Save
            </button>
          )}
          {!selectedTag && (
            <button
              className='rounded bg-indigo-600 p-2 px-4 font-semibold text-white hover:bg-indigo-800 disabled:cursor-not-allowed disabled:bg-indigo-300'
              type='submit'
              onClick={() => addTag(name, type)}
              data-testid='reject-submission-submit-btn'
              disabled={name.length === 0 || type.length === 0}
            >
              Add
            </button>
          )}
          <button
            type='button'
            className='rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200'
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      }
    >
      <div className='mt-6 max-w-xl text-base text-gray-600 dark:text-gray-300'>
        <p className=''>Add a new tag to your label library</p>
      </div>
      <div className='flex flex-col space-y-6'>
        <div className='w-full sm:max-w-sm'>
          <Input
            label='Name'
            // error={name.length === 0}
            onChange={(e) => setName(e.target.value)}
            value={name}
            type='text'
            name='name'
            id='name'
            className={globalThemeStyles.inputbox}
            data-testid='add-tag'
          />
        </div>
        <div className='w-full sm:max-w-sm'>
          <label
            htmlFor={'type'}
            className={`flex items-center text-sm font-medium dark:text-slate-200 `}
          >
            Type&nbsp;
            {/* {hint && <InfoTooltip hint={hint} />} */}
          </label>
          <NiceSelect
            canBeModified={true}
            catalogue={type}
            updateCatalogue={setType}
            options={types}
          />
        </div>
      </div>
    </GenericDialog>
  );
}

interface TagSelectProps {
  canBeModified: boolean;
  catalogue: string;
  updateCatalogue: (value: string) => void;
  options: string[];
}

export default function NiceSelect({
  canBeModified,
  catalogue,
  updateCatalogue,
  options,
}: TagSelectProps) {
  return (
    <Listbox
      value={catalogue}
      onChange={updateCatalogue}
      disabled={!canBeModified}
    >
      <div className='w-ful relative mt-3'>
        <ListboxButton
          className={`border-1 w-full cursor-default border border-slate-100 py-2.5 pl-3 pr-10 text-left shadow-md sm:text-sm ${globalThemeStyles.inputbox}`}
        >
          <span className=' truncate'>
            {catalogue ? catalogue : 'Not selected'}
          </span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronDownIcon
              aria-hidden='true'
              className='h-5 w-5 text-gray-400'
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in dark:bg-slate-800 dark:text-slate-200 sm:text-sm'
        >
          {options.map((person) => (
            <ListboxOption
              key={person}
              value={person}
              className='group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600 data-[focus]:text-white dark:text-slate-200'
            >
              <span className='block truncate font-normal group-data-[selected]:font-semibold'>
                {person}
              </span>

              <span className='absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden'>
                <CheckIcon aria-hidden='true' className='h-5 w-5' />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}

function mapTagTypeToIcon(type: string) {
  switch (type) {
    case 'MUSICFOR':
      return <BiMusic size={20} />;
    case 'MOOD':
      return <BiSun size={20} />;
    case 'KEYWORD':
      return <BiSearchAlt size={20} />;
    case 'STYLE':
      return <BiPalette size={20} />;
    case 'INSTRUMENT':
      return <MdPiano size={20} />;
    case 'ELEMENT':
      return <BiCube size={20} />;
    case 'CATALOGUE':
      return <BiBook size={20} />;
    default:
      return <BiTag size={20} />;
  }
}

function mapTagTypeToColorClass(type: string) {
  switch (type) {
    case 'MUSICFOR':
      return 'bg-indigo-500 text-white';
    case 'MOOD':
      return 'bg-amber-500 text-white';
    case 'KEYWORD':
      return 'bg-teal-500 text-white';
    case 'STYLE':
      return 'bg-blue-500 text-white';
    case 'INSTRUMENT':
      return 'bg-red-500 text-white';
    case 'ELEMENT':
      return 'bg-purple-500 text-white';
    case 'CATALOGUE':
      return 'bg-emerald-500 text-white';
    default:
      return 'bg-gray-500 text-white';
  }
}

function ListTagsForm({
  isOpen,
  setIsOpen,
  setIsEditOpen,
  type,
  tags,
  reloadTags,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  setIsEditOpen: (tagId: string) => void;
  reloadTags: () => void;
  type: string;
  tags: ITag[];
}) {
  const deleteTag = async (id: string) => {
    try {
      await api.tag.deleteTag(id);
      toast.success('Tag deleted successfully!');
      reloadTags();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={
        <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
      }
      header={`${type} Tags`}
      headerIcon={mapTagTypeToIcon(type)}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={
        <div className='mt-2 flex items-center justify-end gap-x-3'>
          <button
            type='button'
            className='rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200'
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      }
    >
      <div className='mt-6 max-w-xl text-base text-gray-600 dark:text-gray-300'>
        <p className=''>
          All <b>{type}</b> tags of your label
        </p>
      </div>
      <div className='flex flex-col space-y-4'>
        {tags.map((tag) => (
          <TagRow tag={tag} deleteTag={deleteTag} editTag={setIsEditOpen} />
        ))}
      </div>
    </GenericDialog>
  );
}

function TagRow({
  tag,
  editTag,
  deleteTag,
}: {
  tag: ITag;
  editTag: (id: string) => void;
  deleteTag: (id: string) => void;
}) {
  return (
    <div
      key={tag.id}
      className={`flex h-24 break-all rounded-lg bg-white py-2 px-4 shadow hover:cursor-pointer hover:bg-slate-50 dark:bg-slate-900 hover:dark:bg-slate-700 md:h-20 lg:h-14 xl:h-14 ${globalThemeStyles.container}`}
    >
      <div className='flex w-full items-center space-x-4'>
        <div className='flex rounded-md bg-slate-500 p-2 text-white '>
          {mapTagTypeToIcon(tag.type)}
        </div>

        <div className='flex-1 '>
          <div className='flex items-center justify-between'>
            <h3 className='text-sm font-medium'>{tag.name}</h3>
            <div className='flex space-x-8'>
              <div
                className={`flex h-5 items-center justify-center rounded px-2.5 py-0.5 text-sm font-semibold ${mapTagTypeToColorClass(
                  tag.type
                )}`}
              >
                <div>{tag.type.toUpperCase()}</div>
              </div>
              <div className='flex space-x-2'>
                <div onClick={() => editTag(tag.id)}>
                  <BiEdit size={20} />
                </div>
                <div onClick={() => deleteTag(tag.id)}>
                  <BiTrash size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
