import { useAuth } from '../hooks/useAuth';
import { useGlobalState } from '../state';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../api';
import { IProject } from '../api/services/project.service';
import AlbumSection from '../components/dashboard/AlbumSection';
import MidnightLogoViolet from '../components/global/midnight_violet.png';
import MidnightLogoYellow from '../components/global/midnight_yellow.png';

export default function Dashboard() {
  const { user, activeTeam } = useAuth();
  const { theme } = useGlobalState();

  const [albums, setAlbums] = useState<IProject[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activeTeam !== null) {
      fetchAlbums();
    }
  }, [activeTeam]);

  async function fetchAlbums() {
    try {
      setLoading(true);
      const { data } = await api.album.getAlbums();
      if (data.result) {
        setAlbums(data.result);
        setLoading(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center'>
          {/* <TourOnboarding /> */}
          {/* Projects */}
          {activeTeam && (
            <>
              <div className=''>
                {theme === 'dark' && (
                  <img className='mt-4 h-16' src={MidnightLogoYellow} />
                )}
                {theme !== 'dark' && (
                  <img className='mt-4 h-16' src={MidnightLogoViolet} />
                )}
              </div>
              <h2 className='ext-center text-2xl font-bold'>
                {activeTeam.name}
              </h2>
              <div className='mt-20 flex w-full px-5 md:mt-8 md:px-20 lg:px-36'>
                <AlbumSection
                  className='h-128'
                  albums={albums}
                  loading={loading}
                />
              </div>
            </>
          )}
          {/* Activity */}
          {/* <div className="flex mt-10 w-full px-5 md:px-20 lg:px-36">
            <ActivitySection />
          </div> */}
        </div>
      )}
    </>
  );
}
