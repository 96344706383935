import { IPaginatedResponse, IResponse, ISearchRequest } from '..';
import client from '../client';

export interface IFile {
  id: string;
  name: string;
  tenantId: string;
  projectId: string;
  parentId: string; // check if null required
  size: number;
  contentType: string;

  isVisible: boolean;
  status: string;
  metadata: any;

  createdBy: string;
  role: 'MAIN' | 'STEMS' | 'ARTWORK' | 'MASTER';

  createdAt: string;
  updatedAt: string;
}

export interface INewFile {
  name: string;
  tenantId: string;
  projectId?: string;
  size?: number;
  role: string;
  parentId?: string;
  status?: string;
  metadata?: any;
  contentType: string;
}

interface IFilePresignResponse {
  objectId: string;
  presignedUrl: string;
}

async function getFilesByProject(projectId: string) {
  return client.get<IResponse<IFile[]>>(`/file/project:${projectId}`);
}

async function getFile(id: string) {
  return client.get<IResponse<IFile>>(`/file/${id}`);
}

async function getFilePresignedUploadUrl(id: string) {
  return client.put<IResponse<IFilePresignResponse>>(`/file/${id}/presign`);
}

async function getFilePresignedDownloadUrl(id: string) {
  return client.get<IResponse<IFilePresignResponse>>(`/file/${id}/presign`);
}

async function getFilePresignedUploadUrlForType(id: string, type: string) {
  return client.put<IResponse<IFilePresignResponse>>(
    `/file/${id}/${type}/presign`
  );
}

async function getFilePresignedDownloadUrlForType(id: string, type: string) {
  return client.get<IResponse<IFilePresignResponse>>(
    `/file/${id}/${type}/presign`
  );
}

async function addFile(file: INewFile) {
  return client.post<IResponse<IFile>>(`/file`, file);
}

async function deleteFile(id: string) {
  return client.delete<IResponse<IFile>>(`/file/${id}`);
}

async function updateFilePartially(fileId: string, file: Partial<IFile>) {
  return client.patch<IResponse<IFile>>(`/file/${fileId}`, file);
}

async function searchFile(req: ISearchRequest) {
  return client.post<IPaginatedResponse<IFile[]>>('/file/search', req);
}

const api = {
  getFilesByProject,
  getFile,
  addFile,
  deleteFile,
  getFilePresignedDownloadUrl,
  getFilePresignedDownloadUrlForType,
  getFilePresignedUploadUrl,
  getFilePresignedUploadUrlForType,
  updateFilePartially,
  searchFile,
};

export default api;
