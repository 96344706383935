import axios from 'axios';
import { Fragment, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IComposer } from '../../../../api/services/composer.service';
import { ITrack } from '../../../../api/services/track.service';

import { Dialog, Transition } from '@headlessui/react';
import { FormikErrors, useFormik } from 'formik';
import { IoCloseSharp } from 'react-icons/io5';
import { IProject } from '../../../../api/services/project.service';
import { globalThemeStyles } from '../../../../config/globalStyles';
import { isEmail } from '../../../../utils';
import DropdownAutocomplete from '../../../shared/DropdownAutoComplete';
import Input from '../../../shared/Input';
import { countries } from './tagOptions';
// import { ChevronDownIcon } from '@heroicons/react/20/solid'

interface Props {
  isOpened: boolean;
  close: () => void;
  project: IProject | null;
  activeComposer: IComposer | null;
  onUpdate: (id: string) => Promise<void>;
  activeTrack: ITrack;
  disabled: boolean;
}

interface FormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  country: string;
  street: string;
  city: string;
  zip: string;
  state: string;
  ipi: string;
  pro: string;
  royaltyShare: string;
}

const PRO_OPTIONS = [
  'ONDA',
  'UNAC-SA',
  'BUBEDRA',
  'COSBOTS',
  'BBDA',
  'CMC',
  'SCM-COOPERATIVA',
  'BCDA',
  'SACERAU',
  'GHAMRO',
  'BGDA',
  'BURIDA',
  'MCSK',
  'OMDA',
  'COSOMA',
  'BUMDA',
  'MASA',
  'BMDA',
  'NASCAM',
  'RSAU',
  'SODAV',
  'SACS',
  'CAPASSO',
  'SAMRO',
  'COSOTA',
  'COSOZA',
  'BUTODRA',
  'OTDAV',
  'UPRS',
  'ZAMCOPS',
  'ZIMURA',
  'APRA',
  'AMCOS',
  'MCSC',
  'CASH',
  'IPRS',
  'WAMI',
  'JASRAC',
  'KOMCA',
  'KOSCAP',
  'MACA',
  'MACP',
  'MOSCAP',
  'CPSN',
  'MRCSN',
  'FILSCAP',
  'COMPASS',
  'MÜST',
  'MCT',
  'VCPMC',
  'SOCAN',
  'SPACQ',
  'ASCAP',
  'AMRA',
  'BMI',
  'ALBAUTOR',
  'AKM',
  'AAS',
  'NCIP',
  'GESAC',
  'IMPF',
  'Sabam',
  'AMUS',
  'MUSICAUTOR',
  'HDS-ZAMP',
  'OSA',
  'KODA',
  'NCB',
  'EAU',
  'TEOSTO',
  'SACENC',
  'SACEM',
  'GCA',
  'GEMA',
  'AUTODIA',
  'EDEM',
  'UFFICIO GIURIDICO',
  'ARTISJUS',
  'STEF',
  'IMRO',
  'ACUM',
  'SIAE',
  'KazAK',
  'ABYROY',
  'AKKA-LAA',
  'LATGA',
  'SACEM LUXEMBOURG',
  'ZAMP Macedonia',
  'ANCO',
  'PAM CG',
  'BUMA',
  'STEMRA',
  'TONO',
  'ZAIKS',
  'SPA',
  'UCMR-ADA',
  'CRSEA',
  'RAO',
  'SOKOJ',
  'SOZA',
  'SAZAS',
  'EKKI',
  'SGAE',
  'STIM',
  'SUISA',
  'MESAM',
  'MSG',
  'NGO-UACRR',
  'PRS',
  'SADAIC',
  'COSCAP',
  'BSCAP',
  'SOBODAYCOM',
  'SOCINPRO',
  'ADDAF',
  'ABRAMUS',
  'ASSIM',
  'AMAR SOMBRÁS',
  'SBACEM',
  'SICAM',
  'UBC',
  'SCD',
  'SAYCO',
  'ACAM',
  'ACDAM',
  'SGACEDOM',
  'SAYCE',
  'SACIM EGC',
  'AEI-GUATEMALA',
  'AACIMH',
  'JACAP',
  'SACM',
  'SPAC',
  'APA',
  'APDAYC',
  'ECCO',
  'SASUR',
  'ACCS',
  'COTT',
  'AGADU',
  'SACVEN',
];

const ViewComposerModal = ({
  isOpened,
  close,
  onUpdate,
  project,
  activeComposer,
  activeTrack,
  disabled,
}: Props) => {
  // const [newComposer, setNewComposer] = useState<Partial<ITrack>>({});
  // const [partialTrack, setPartialTrack] = useState<Partial<ITrack>>({});
  // const min = 1;
  // const max = 6; //TODO: restrict number of composers for track
  // const [loading, setLoading] = useState(false);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      firstName: activeComposer?.firstName || '',
      middleName: activeComposer?.middleName || '',
      lastName: activeComposer?.lastName || '',
      email: activeComposer?.email || '',
      country: activeComposer?.country || countries[0],
      street: activeComposer?.street || '',
      city: activeComposer?.city || '',
      zip: activeComposer?.zip || '',
      state: activeComposer?.state || '',
      ipi: activeComposer?.ipi || '',
      pro: activeComposer?.pro || '',
      royaltyShare: String(activeComposer?.royaltyShare || 0),
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.firstName) {
        errors.firstName = 'required';
      }

      if (!values.lastName) {
        errors.lastName = 'required';
      }

      if (!values.email) {
        errors.email = 'required';
      }

      if (values.email && !isEmail(values.email)) {
        errors.email = 'invalid';
      }

      if (!values.country) {
        errors.country = 'required';
      }

      if (!values.street) {
        errors.street = 'required';
      }

      if (!values.city) {
        errors.city = 'required';
      }

      if (!values.zip) {
        errors.zip = 'required';
      }

      if (!values.state) {
        errors.state = 'required';
      }

      if (!values.ipi) {
        errors.ipi = 'required';
      }

      if (!values.pro) {
        errors.pro = 'required';
      }

      // if (!values.royaltyShare) {
      //   errors.royaltyShare = "required";
      // }

      return errors;
    },
    onSubmit: async ({
      firstName,
      middleName,
      lastName,
      email,
      country,
      street,
      city,
      zip,
      state,
      ipi,
      pro,
      royaltyShare,
    }) => {
      try {
        if (activeComposer == null) {
          const response = await api.composer.addComposer({
            firstName,
            lastName,
            email,
            country,
            street,
            city,
            zip,
            state,
            ipi,
            pro,
            royaltyShare,
            middleName,
            teamId: project?.teamId || '',
          });
          if (response.data.result) {
            await api.composer.addComposerToTrack(
              response.data.result?.id,
              activeTrack.id
            );
            toast.success('Composer added successfully');
            onUpdate(response.data.result.id);
            handleClose();
          }

          return;
        }

        // setLoading(true);
        await api.composer.updateComposerPartially(activeComposer?.id, {
          firstName,
          middleName,
          lastName,
          email,
          country,
          street,
          city,
          zip,
          state,
          ipi,
          pro,
          royaltyShare: +royaltyShare,
        });
        // setLoading(false);
        toast.success('Composer updated successfully');
        onUpdate(activeComposer.id);
        handleClose();
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response?.data) {
            toast.error(e.response?.data.errorMessage);
          }
        } else {
          console.log(e);
          toast.error(
            'Something really went wrong, you might want to contact support!'
          );
        }
      }
    },
  });

  const handleClose = () => {
    formik.resetForm();
    close();
  };

  const { isValid, submitCount, errors } = formik;
  useEffect(() => {
    if (submitCount > 0 && !isValid) {
      toast.error(
        <div>
          <div className='mb-1'>Please complete all required fields</div>
          <div className='whitespace-pre-line'>
            {Object.entries(errors)
              .map(([k, v]) => `${k}: ${v}`)
              .join(',\n')}
          </div>
        </div>
      );
    }
    // eslint-disable-next-line
  }, [isValid, submitCount]);

  return (
    <Transition appear show={isOpened} as={Fragment}>
      <Dialog as='div' className='relative z-10 w-full' onClose={close}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-25' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto text-slate-700'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full bg-white px-4 py-5 shadow dark:bg-slate-900 sm:rounded-lg sm:pt-12 md:max-w-4xl'>
                <div className='relative flex justify-end pr-10'>
                  <button
                    onClick={close}
                    className='cursor-pointer text-gray-500 transition-transform duration-300 hover:rotate-90'
                  >
                    <IoCloseSharp className='h-8 w-8 dark:fill-gray-300' />
                  </button>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className='mx-16 gap-6 md:grid md:grid-cols-1'>
                    <div className='flex justify-start'>
                      <span className='border-b-2 border-indigo-500 text-2xl font-medium leading-6 text-gray-600 dark:text-gray-300'>
                        Composer Data
                      </span>
                    </div>
                    <div className='mt-5 space-y-6 md:col-span-2 md:mt-0'>
                      <div className='grid grid-cols-3 gap-6 pt-4'>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            label='First Name *'
                            error={
                              !!formik.errors.firstName &&
                              formik.touched.firstName
                            }
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            type='text'
                            name='firstName'
                            id='first-name'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            label='Middle Name'
                            error={
                              !!formik.errors.middleName &&
                              formik.touched.middleName
                            }
                            onChange={formik.handleChange}
                            value={formik.values.middleName}
                            type='text'
                            name='middleName'
                            id='middle-name'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-2'>
                          <Input
                            label='Last name *'
                            error={
                              !!formik.errors.lastName &&
                              formik.touched.lastName
                            }
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            type='text'
                            name='lastName'
                            id='last-name'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 md:col-span-3'>
                          <Input
                            label='Email address *'
                            error={
                              !!formik.errors.email && formik.touched.email
                            }
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            type='text'
                            name='email'
                            id='email-address'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3'>
                          <label
                            htmlFor='country'
                            className='block text-sm font-medium'
                          >
                            Country *
                          </label>
                          <select
                            onChange={(e) =>
                              formik.setFieldValue(
                                'country',
                                e.target.value,
                                true
                              )
                            }
                            value={formik.values.country}
                            id='country'
                            name='country'
                            disabled={disabled}
                            className={`mt-1 block w-full rounded-md py-2 px-3 sm:text-sm ${globalThemeStyles.inputbox}`}
                          >
                            {countries.map((country, index) => {
                              return <option key={index}>{country}</option>;
                            })}
                          </select>
                        </div>
                        <div className='col-span-6'>
                          <Input
                            label='Street address *'
                            error={
                              !!formik.errors.street && formik.touched.street
                            }
                            name='street'
                            onChange={formik.handleChange}
                            value={formik.values.street}
                            type='text'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-6 lg:col-span-2'>
                          <Input
                            label='City *'
                            error={!!formik.errors.city && formik.touched.city}
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            type='text'
                            name='city'
                            id='city'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                          <Input
                            label='ZIP / Postal code *'
                            error={!!formik.errors.zip && formik.touched.zip}
                            onChange={formik.handleChange}
                            value={formik.values.zip}
                            type='text'
                            name='zip'
                            id='postal-code'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3 lg:col-span-2'>
                          <Input
                            label='State / Province *'
                            error={
                              !!formik.errors.state && formik.touched.state
                            }
                            onChange={formik.handleChange}
                            value={formik.values.state}
                            type='text'
                            name='state'
                            id='region'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3 lg:col-span-3'>
                          {/* <Input
                         label="Performing Rights Organisation (PRO) *"
                         error={!!formik.errors.pro && formik.touched.pro}
                         onChange={formik.handleChange}
                         value={formik.values.pro}
                         name="pro"
                         type="text"
                         disabled={disabled}
                         className={`${globalThemeStyles.inputbox}`}
                       /> */}
                          <DropdownAutocomplete
                            label='Performing Rights Organisation (PRO) *'
                            options={PRO_OPTIONS}
                            getValue={(v) => v}
                            displayValue={(v) => v}
                            displayOption={(v) => v}
                            filterFn={(q: string) => (v: string) =>
                              v.toLowerCase().startsWith(q.toLowerCase())}
                            value={formik.values.pro}
                            onChange={(v: string) => {
                              formik.setFieldValue('pro', v);
                            }}
                            error={!!formik.errors.pro && formik.touched.pro}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-3 lg:col-span-3'>
                          <Input
                            hint='The IPI is a number assigned to you by your PRO that uniquely identifies you as a composer. The IPI is 9-11 digits long, depending on the PRO.'
                            label='Interested Party Information Number (IPI) *'
                            error={!!formik.errors.ipi && formik.touched.ipi}
                            onChange={formik.handleChange}
                            value={formik.values.ipi}
                            type='text'
                            disabled={disabled}
                            className={`${globalThemeStyles.inputbox}`}
                            name='ipi'
                          />
                        </div>
                        {/* <div className="col-span-6 sm:col-span-2 lg:col-span-1">
                       <label className="block text-sm font-medium">
                         Royalty Share *
                       </label>
                       <Input
                         name='royaltyShare'
                         onChange={formik.handleChange}
                         value={formik.values.royaltyShare}
                         type="number"
                         pattern="[0-9]*"
                         className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                       />
                     </div> */}
                      </div>
                      <div className='flex w-full justify-end'>
                        <div className='text-sm text-slate-400'>
                          * = required Fields
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='mt-10 flex justify-end'>
                    <button
                      disabled={disabled}
                      type='submit'
                      className='mr-8 mb-7 -mt-7 flex items-center space-x-2 rounded bg-emerald-600 py-1 px-4 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
                    >
                      <div>Save</div>
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ViewComposerModal;
