import { Link, useLocation, useNavigate } from 'react-router-dom';
import { KTRLogo } from '../../components/shared/ktr';
import { useAuth } from '../../hooks/useAuth';

export default function Forbidden() {
  let navigate = useNavigate();
  let { user } = useAuth();
  let location = useLocation();

  return (
    <div className='mt-20 flex flex-grow'>
      <div className='flex w-full flex-col items-center'>
        <div className='h-40 w-40'>
          <KTRLogo />
        </div>
        <h2 className='mt-6 text-center font-mono text-3xl font-extrabold text-gray-900'>
          403 Forbidden
        </h2>
        <p className='mt-2 text-center text-sm text-gray-600'>
          Seems like you don't have the required permissions to access this
          project.
        </p>
        {!user && (
          <p className='mt-2 text-center text-sm text-gray-600'>
            If you have an existing account logging in might help.
          </p>
        )}
        <div className='mt-7 flex-col items-center'>
          {!user && (
            <Link
              to='/login'
              state={{ from: location }}
              className='relative mb-2 flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-semibold text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
            >
              Login
            </Link>
          )}
          <div
            className='mt-8 flex cursor-pointer justify-center text-indigo-600'
            onClick={() => navigate(-1)}
          >
            Go Back
          </div>
        </div>
      </div>
    </div>
  );
}
